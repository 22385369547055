.project-carousel {
  background-color: #fffefe;
  padding: 50px;
  background-image: url(../../resources/images/76825.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // background-blend-mode: lighten;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(80vh);
}

// .item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 250px;
//   width: 100%;
//   background-color: #683bb7;
//   color: #fff;
//   margin: 15px;
//   font-size: 4em;
//   box-shadow: 5px 10px #888888;
// }

.carousel-content {
  width: 350px !important;
  border-radius: 10px;
  bottom: 0%;
  box-shadow: 3px 5px #0085b5;
  a {
    text-decoration: none;
    color: #fff;
  }
  .carousel-title {
    position: absolute;
    font-family: "Montserrat", sans-serif;
    bottom: 8px;
    left: 10px;
  }
}
.eAyARa {
  background-color: rgb(230, 230, 230) !important;
  &:hover {
    color: #00213a;
  }
}
.eWjCzc {
  background-color: #fff !important;
  &:hover {
    color: #00213a !important;
  }
}
.krmNah {
  background-color: #fff !important;
  box-shadow: 0 0 3px 3px #3bbcdc !important;
}
.jhmYzC {
  background-color: rgb(155, 155, 155) !important;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.model-title {
  text-transform: uppercase;
}
@media (min-width: 300px) and (max-width: 768px) {
  .project-carousel {
    padding: 30px;
  }
  .krmNah {
    height: 7px !important;
    width: 7px !important;
  }
  .jhmYzC {
    height: 7px !important;
    width: 7px !important;
  }
}
