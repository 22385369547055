// .thanks-section {
//   background-color: #0e256f;
//   min-height: calc(100vh - 100px);
//   color: rgb(255, 255, 255);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   h1 {
//     font-family: "Montserrat", sans-serif;
//     font-weight: bolder;
//     padding: 15px;
//     text-align: center;
//   }
//   h4{
//     font-family: "Poppins", sans-serif;
//     padding: 10px;
//     text-align: center;
//   }
//   .buttonContainer {
//     margin-top: 10px;
//     display: flex;
//     justify-content: center;

//     .enquireBTN {
//       padding: 10px 20px;
//       background: #44b34d;
//       border-radius: 4px;

//       color: #ffffff;
//       cursor: pointer;
//       border: none;
//       outline: none;
//       margin-right: 30px;
//     }
//     .whatsappBTN {
//       padding: 10px 40px;
//       background: transparent;
//       color: #44b34d;
//       border: 1px solid #44b34d;
//       border-radius: 41px;
//       cursor: pointer;
//       a {
//         color: #44b34d;
//         text-decoration: none;
//       }
//       .whatsappIcon {
//         margin-right: 10px;
//       }
//     }
//   }
// }
.thanks-section {
  background-color: #0e256f;
  min-height: calc(100vh - 76px);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .wrapper-1 {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .wrapper-2 {
      padding: 30px;
      text-align: center;
      h1 {
        font-family: "Kaushan Script", cursive;
        font-size: 6em;
        letter-spacing: 3px;
        color: #44b34d;
        margin: 0;
        margin-bottom: 20px;
      }
      p {
        margin: 20px;
        font-size: 1.3em;
        color: rgb(245, 245, 245);
        font-family: "Source Sans Pro", sans-serif;
        letter-spacing: 1px;
        margin-bottom: 15px;
      }
      .buttonContainer {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .enquireBTN {
          padding: 10px 20px;
          background: #44b34d;
          border-radius: 4px;
          color: #ffffff;
          cursor: pointer;
          border: none;
          outline: none;
          margin-right: 30px;
        }
        .whatsappBTN {
          padding: 10px 40px;
          background: transparent;
          color: #44b34d;
          border: 1px solid #44b34d;
          border-radius: 41px;
          cursor: pointer;
          a {
            color: #44b34d;
            text-decoration: none;
          }
          .whatsappIcon {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .thanks-section {
    .wrapper-1 {
      .wrapper-2 {
        h1 {
          font-size: 5em;
        }
        p {
          font-size: 1em;
        }
        .buttonContainer {
          margin-top: 10px;
          display: flex;
          align-items: center;
          flex-direction: column;

          .enquireBTN {
            margin-right: 0px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .buttonContainer {
    margin: 30px;
  }
  .wrapper-1 {
    height: initial;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
  }
}
