.expiry-form {
  animation: blinker 1.5s step-start infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact-form-section {
  // background:
  // 	linear-gradient(180deg, #99b9c1 (32px - 10px), transparent 1%) center,
  // 	linear-gradient(#9cb3b8 (32px - 10px), transparent 1%) center,
  // 	 #ffffff;
  // background-size: 22px 22px;
  // background-attachment: fixed;
  // #9cb3b8
  //   B3D3DE
  background-image: url(../../resources/images/76825.jpg);
  background-size: 100% 100%;
  // background-attachment: fixed;
  // background-blend-mode: hard-light;
  background-color: #fffefe;
  min-height: calc(90vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // color: #000000;
  .formBox {
    box-shadow: 3px 3px rgb(96, 150, 178);
    max-width: 460px;
    text-align: center;
    margin: 20px auto;
    background: #0e256f;
    border-radius: 10px;
    padding: 20px 30px;
    p {
      color: #41d302;
      font-weight: normal;font-family: 'Montserrat', sans-serif;
    }
    .expiry-form {
      text-align: center;
      color: rgb(218, 53, 53);
      margin-bottom: 5px;
      font-family: 'Poppins', sans-serif;
    }
    .offerText {
      color: #fff;
      font-weight: bolder;
      font-size: 24px;
      font-family: 'Montserrat', sans-serif;
    }
    form {
      display: flex;
      margin-top: 30px;
      flex-direction: column;
      justify-content: center;

      input,
      textarea {
        width: 100%;
        background: #ffffff;
        border: none;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 13px;
        border: 1px solid rgba(0, 0, 0, 0.18);
        // box-shadow: 3px 3px rgb(96, 150, 178);
        font-family: 'Poppins', sans-serif;
        // box-shadow: 3px 3px rgb(96, 150, 178);
      }
      input::placeholder,
      textarea::placeholder {
        font-family: 'Poppins', sans-serif;
      }
      input:focus {
        outline: none;
      }
      button {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 50%;font-family: 'Montserrat', sans-serif;
        padding: 10px 20px;
        outline: none;
        border: none;
        background: linear-gradient(to right, #00af23, #44b34d);
        border-radius: 3.011px;
        cursor: pointer;
        color: #fff;
        // font-weight: 200;
       
      }
      button:disabled {
        background: linear-gradient(to right, #73eb8b, #8fe296);
        cursor: not-allowed;
        color: #fff;
      }
    }
  }
}
.recaptcha {
  margin-left: auto;
  margin-right: auto;
}
