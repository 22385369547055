.our-service {
  background-color: #0e256f;
  min-height: calc(90vh);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-family: "Montserrat", sans-serif;
    margin: 50px auto 30px auto;
  }
  .service-card {
    h5 {
      padding: 40px 10px 0px 10px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
    }
    p {
      color: rgb(162, 175, 177);
      padding: 2%;
      font-family: "Poppins", sans-serif;
    }
  }
}
@media screen and (min-width: 768px) {
  .our-service {
    
    .service-card {
      p {
        text-align: left;
      }
    }
  }
}