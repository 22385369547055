.cta-section {
  background-color: #fffefe;
  background-image: url(../../resources/images/76825.jpg);
  background-position: center;
  background-size: cover;
  min-height: calc(90vh);
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5% 1%;
  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }
  .cta-content {
    margin: 50px;
  }
  ul {
    padding: 25px 0px;
    // border-radius: 7px;
    box-shadow: 3px 3px 5px rgb(96, 150, 178);
  }
  li {
    // font-size: large;
    font-family: "Poppins", sans-serif;
    line-height: 2.5;
  }
}
@media (min-width: 300px) and (max-width: 950px) {
  .cta-section {
    .cta-content {
      margin: 20px;
    }
    ul {
      box-shadow: none;
    }
  }
}
