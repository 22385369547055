.partners {
  background-color: #0e256f;
  // padding: 2%;
}
.marquee {
  overflow: hidden;
}

.marquee-content {
  display: flex;
  animation: scrolling 10s linear infinite;
}

.marquee-item {
  flex: 0 0 150px;
  margin: auto 40px;
}

.marquee-item img {
  display: block;
  width: 100%;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-80vw);
  }
}
