.timeline {
  background-color: #0e256f;
  color: #fff;
}
.services {
  padding: 50px 0;
  --bs-gutter-x: 0 !important;
  background-color: #214fcf;
  h4 {
    color: #fafafa;
  }
  .enquireBTN {
    padding: 10px 30px;
    white-space: nowrap;
    margin: 10px 30px 0 30px;
    background: #44b34d;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    outline: none;
    margin-right: 30px;
  }
}
._2_32njdU {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  width: 1272px;
}
@media (min-width: 1024px) {
  ._2_32njdU {
    padding-left: 36px;
    padding-right: 36px;
  }
}
._3Wv9utNN a:focus {
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 2px;
}
._3Wv9utNN a:focus:not(:focus-visible) {
  outline: 0;
  outline-offset: 0;
}
._7QdK4zyR {
  align-items: center;
  background: rgba(38, 45, 61, 0.95);
  bottom: -1000px;
  display: flex;
  font-weight: 100;
  justify-content: space-between;
  left: 0;
  opacity: 0;
  padding: 20px 15px;
  position: fixed;
  right: 0;
  transition-delay: 0s, 0.3s;
  transition-duration: 0.3s, 0.01s;
  transition-property: opacity, bottom;
  z-index: 100;
}
@media (min-width: 1024px) {
  ._7QdK4zyR {
    padding: 20px 50px;
  }
}
@media (max-width: 767px) {
  ._2_Bs6D8F {
    flex-direction: column;
    text-align: center;
  }
}
._4F0hjFXa {
  align-items: center;
  color: #0f256e;
  display: flex;
  font-size: 44px;
  line-height: 55px;
  margin: 0 auto 43px;
}
._4F0hjFXa h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
@media (max-width: 524px) {
  ._4F0hjFXa {
    align-items: center;
    flex-direction: column;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
  }
}
.VRzpBOaS {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
._3jgwFSUz {
  margin: 0;
}
.a5wuXPKO {
  margin: -4px;
  width: calc(100% + 8px);
}
.a5wuXPKO > ._3jgwFSUz {
  padding: 4px;
}
.xK1Y6wFp {
  margin: -8px;
  width: calc(100% + 16px);
}
.xK1Y6wFp > ._3jgwFSUz {
  padding: 8px;
}
.h7NHpGkN {
  margin: -12px;
  width: calc(100% + 24px);
}
.h7NHpGkN > ._3jgwFSUz {
  padding: 12px;
}
._2lPvBDZI {
  margin: -16px;
  width: calc(100% + 32px);
}
._2lPvBDZI > ._3jgwFSUz {
  padding: 16px;
}
._1GwNtStZ {
  flex-basis: 8.33333%;
  flex-shrink: 0;
  max-width: 8.33333%;
}
._2Mx0cghk {
  flex-basis: 16.66667%;
  flex-shrink: 0;
  max-width: 16.66667%;
}
._2NAIDFKi {
  flex-basis: 25%;
  flex-shrink: 0;
  max-width: 25%;
}
._2UDh_G12 {
  flex-basis: 33.33333%;
  flex-shrink: 0;
  max-width: 33.33333%;
}
._3v8dne6s {
  flex-basis: 41.66667%;
  flex-shrink: 0;
  max-width: 41.66667%;
}
._2N8pXk9N {
  flex-basis: 50%;
  flex-shrink: 0;
  max-width: 50%;
}
.jgRVL1If {
  flex-basis: 58.33333%;
  flex-shrink: 0;
  max-width: 58.33333%;
}
._1mhTHHWq {
  flex-basis: 66.66667%;
  flex-shrink: 0;
  max-width: 66.66667%;
}
._3KmeAScq {
  flex-basis: 75%;
  flex-shrink: 0;
  max-width: 75%;
}
._3Jnmt4_u {
  flex-basis: 83.33333%;
  flex-shrink: 0;
  max-width: 83.33333%;
}
._3EB6kCHm {
  flex-basis: 91.66667%;
  flex-shrink: 0;
  max-width: 91.66667%;
}
.RqckegCL {
  flex-basis: 100%;
  flex-shrink: 0;
  max-width: 100%;
}
@media (min-width: 768px) {
  ._1CZDjbCH {
    margin: -4px;
    width: calc(100% + 8px);
  }
  ._1CZDjbCH > ._3jgwFSUz {
    padding: 4px;
  }
  .SdDoLGVe {
    margin: -8px;
    width: calc(100% + 16px);
  }
  .SdDoLGVe > ._3jgwFSUz {
    padding: 8px;
  }
  .nSXNjloy {
    margin: -12px;
    width: calc(100% + 24px);
  }
  .nSXNjloy > ._3jgwFSUz {
    padding: 12px;
  }
  ._2d4v8X-a {
    margin: -16px;
    width: calc(100% + 32px);
  }
  ._2d4v8X-a > ._3jgwFSUz {
    padding: 16px;
  }
  ._1KQ1Gi0G {
    flex-basis: 8.33333%;
    flex-shrink: 0;
    max-width: 8.33333%;
  }
  ._37O2S15T {
    flex-basis: 16.66667%;
    flex-shrink: 0;
    max-width: 16.66667%;
  }
  ._1HQVvDUb {
    flex-basis: 25%;
    flex-shrink: 0;
    max-width: 25%;
  }
  ._2lgV_Xr9 {
    flex-basis: 33.33333%;
    flex-shrink: 0;
    max-width: 33.33333%;
  }
  ._1rLP5r_m {
    flex-basis: 41.66667%;
    flex-shrink: 0;
    max-width: 41.66667%;
  }
  ._26ebzZtt {
    flex-basis: 50%;
    flex-shrink: 0;
    max-width: 50%;
  }
  ._2u3R8p7l {
    flex-basis: 58.33333%;
    flex-shrink: 0;
    max-width: 58.33333%;
  }
  ._25gHwGaQ {
    flex-basis: 66.66667%;
    flex-shrink: 0;
    max-width: 66.66667%;
  }
  ._2iRj5_FL {
    flex-basis: 75%;
    flex-shrink: 0;
    max-width: 75%;
  }
  ._2MdNxXb- {
    flex-basis: 83.33333%;
    flex-shrink: 0;
    max-width: 83.33333%;
  }
  ._2xplsVD8 {
    flex-basis: 91.66667%;
    flex-shrink: 0;
    max-width: 91.66667%;
  }
  ._1s3M2fiC {
    flex-basis: 100%;
    flex-shrink: 0;
    max-width: 100%;
  }
}
._3B73q8pI {
  justify-content: center;
  margin-bottom: -40px;
}
._2dbxKckM ._2ecxW5_b,
._2FXfVeAR ._2ecxW5_b {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.32);
}
._3fZLMPLS._2dbxKckM {
  background: #0e256f;
  padding: 96px 0;
}
._3fZLMPLS.LI2t_2-I {
  padding: 0;
}
._35agMcd- {
  background-color: #fff;
  color: #000;
  display: flex;
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  justify-content: center;
  line-height: 36px;
  margin-bottom: 48px;
  text-align: center;
}
@media (min-width: 768px) {
  ._35agMcd- {
    font-size: 52px;
    font-weight: 400;
    line-height: 64px;
  }
}
._2dbxKckM ._35agMcd- {
  background-color: #0e256f;
  color: #fff;
}
._35agMcd- strong {
  font-weight: 600;
}
@media (min-width: 768px) {
  ._1epx56Ug {
    overflow: hidden;
  }
}
.TTvbhb5G {
  position: relative;
}
.p0URF4Wy {
  background: #0e256f;
  position: absolute;
  transform: scale(0);
}
.lnGffDGZ .p0URF4Wy {
  transform: none;
}
._2dbxKckM .p0URF4Wy {
  background: #44b34d;
}
@media (max-width: 767px) {
  .p0URF4Wy {
    bottom: 0;
    left: 24px;
    margin: 0;
    top: 64px;
    width: 1px;
  }
  .p0URF4Wy:first-child {
    display: none;
  }
}
._1BxnkSO_ .p0URF4Wy:first-child {
  display: none;
}
._2Rtbw_d5 .p0URF4Wy:first-child {
  left: 0;
  margin-left: -48px;
  opacity: 0.2;
  width: 50%;
}
@media (min-width: 768px) {
  .p0URF4Wy {
    height: 1px;
    top: 32px;
  }
  ._1BxnkSO_ .p0URF4Wy {
    left: 64px;
    margin-left: 24px;
    right: 0;
  }
  ._2Rtbw_d5 .p0URF4Wy {
    left: 50%;
    margin-left: -24px;
    width: 100%;
  }
}
._3c1caa7v {
  color: #0e256f;
  content: "";
  display: block;
  height: 29px;
  position: absolute;
  right: 0;
  top: -14px;
  width: 15px;
}
._2dbxKckM ._3c1caa7v {
  color: #44b34d;
}
@media (max-width: 767px) {
  ._3c1caa7v {
    bottom: -8px;
    left: -7px;
    top: auto;
    transform: rotate(90deg);
  }
}
._2pfxVgGF {
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  ._2pfxVgGF {
    display: flex;
    min-height: 96px;
  }
}
._1BxnkSO_ ._2pfxVgGF {
  text-align: left;
}
@media (min-width: 768px) {
  ._2Rtbw_d5 ._1P9Tvb24:last-child .p0URF4Wy {
    margin-left: 0;
    opacity: 0.2;
    width: 50%;
  }
}
._2qGKqRqZ ._1P9Tvb24:first-child ._2ecxW5_b {
  -webkit-animation: _3OLpik2C 0.15s;
  animation: _3OLpik2C 0.15s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:first-child ._1TA7PM5e {
  -webkit-animation: qDI0Ujkt 0.42s;
  animation: qDI0Ujkt 0.42s;
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:first-child .p0URF4Wy {
  -webkit-animation: tnt6E2os 0.12s;
  animation: tnt6E2os 0.12s;
  -webkit-animation-delay: 0.87s;
  animation-delay: 0.87s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:first-child .p0URF4Wy:first-child {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(2) ._2ecxW5_b {
  -webkit-animation: _3OLpik2C 0.15s;
  animation: _3OLpik2C 0.15s;
  -webkit-animation-delay: 1.17s;
  animation-delay: 1.17s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(2) ._1TA7PM5e {
  -webkit-animation: qDI0Ujkt 0.42s;
  animation: qDI0Ujkt 0.42s;
  -webkit-animation-delay: 1.32s;
  animation-delay: 1.32s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(2) .p0URF4Wy {
  -webkit-animation: tnt6E2os 0.12s;
  animation: tnt6E2os 0.12s;
  -webkit-animation-delay: 1.74s;
  animation-delay: 1.74s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(2) .p0URF4Wy:first-child {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(3) ._2ecxW5_b {
  -webkit-animation: _3OLpik2C 0.15s;
  animation: _3OLpik2C 0.15s;
  -webkit-animation-delay: 2.04s;
  animation-delay: 2.04s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(3) ._1TA7PM5e {
  -webkit-animation: qDI0Ujkt 0.42s;
  animation: qDI0Ujkt 0.42s;
  -webkit-animation-delay: 2.19s;
  animation-delay: 2.19s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(3) .p0URF4Wy {
  -webkit-animation: tnt6E2os 0.12s;
  animation: tnt6E2os 0.12s;
  -webkit-animation-delay: 2.61s;
  animation-delay: 2.61s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(3) .p0URF4Wy:first-child {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(4) ._2ecxW5_b {
  -webkit-animation: _3OLpik2C 0.15s;
  animation: _3OLpik2C 0.15s;
  -webkit-animation-delay: 2.91s;
  animation-delay: 2.91s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(4) ._1TA7PM5e {
  -webkit-animation: qDI0Ujkt 0.42s;
  animation: qDI0Ujkt 0.42s;
  -webkit-animation-delay: 3.06s;
  animation-delay: 3.06s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(4) .p0URF4Wy {
  -webkit-animation: tnt6E2os 0.12s;
  animation: tnt6E2os 0.12s;
  -webkit-animation-delay: 3.48s;
  animation-delay: 3.48s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
._2qGKqRqZ ._1P9Tvb24:nth-child(4) .p0URF4Wy:first-child {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
._2ecxW5_b {
  align-items: center;
  background: #fff;
  border: 1px solid #0e256f;
  border-radius: 50%;
  color: #0e256f;
  display: flex;
  display: inline-flex;
  flex: none;
  flex-direction: row;
  font-size: 32px;
  font-weight: 400;
  height: 64px;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  line-height: 60px;
  opacity: 0;
  position: relative;
  width: 64px;
  z-index: 2;
}
.lnGffDGZ ._2ecxW5_b {
  opacity: 1;
}
@media (max-width: 767px) {
  ._2ecxW5_b {
    font-size: 20px;
    font-weight: 400;
    height: 48px;
    line-height: 28px;
    width: 48px;
  }
}
._2dbxKckM ._2ecxW5_b,
._2FXfVeAR ._2ecxW5_b {
  background: #0f256e;
  border: none;
  color: #fff;
}
._2dbxKckM ._2ecxW5_b {
  background: #44b34d;
}
._2FXfVeAR ._2ecxW5_b {
  background: #0e256f;
}
._1TA7PM5e {
  opacity: 0;
}
.lnGffDGZ ._1TA7PM5e {
  opacity: 1;
}
@media (max-width: 767px) {
  ._1TA7PM5e {
    padding-left: 24px;
    text-align: left;
  }
  ._1P9Tvb24:not(:last-child) ._1TA7PM5e {
    padding-bottom: 32px;
  }
}
._2yVHU0Wx {
  background-color: #fff;
  color: #262d3d;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  ._2yVHU0Wx {
    padding-top: 48px;
  }
}
._2dbxKckM ._2yVHU0Wx {
  background-color: #0e256f;
  color: #fff;
}
._2FXfVeAR ._2yVHU0Wx {
  color: #000;
}
@media (min-width: 768px) {
  ._2Rtbw_d5 ._2yVHU0Wx {
    padding-left: 32px;
    padding-right: 32px;
  }
}
._12ydEHM0 {
  background-color: #fff;
  color: #262d3d;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
._2dbxKckM ._12ydEHM0 {
  background-color: #0e256f;
  color: #fff;
}
._2FXfVeAR ._12ydEHM0 {
  color: #455065;
  font-weight: 400;
}
@-webkit-keyframes tnt6E2os {
  0% {
    transform: scale(0, 0.3);
    transform-origin: 0 50%;
  }
  to {
    transform: scale(1);
    transform-origin: 0 50%;
  }
}
@keyframes tnt6E2os {
  0% {
    transform: scale(0, 0.3);
    transform-origin: 0 50%;
  }
  to {
    transform: scale(1);
    transform-origin: 0 50%;
  }
}
@-webkit-keyframes _3OLpik2C {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes _3OLpik2C {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes qDI0Ujkt {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes qDI0Ujkt {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
