.heroSection {
  background-color: #0e256f;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  color: #fff;
}
.leftBox {
  margin: 20px auto;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin-bottom: 25px;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: #dfdcdc;
    margin-bottom: 60px;
  }
  .buttonBox {
    .enquireBTN {
      font-family: "Montserrat", sans-serif;
      padding: 10px 20px;
      background: #44b34d;
      border-radius: 4px;
      color: #ffffff;
      cursor: pointer;
      border: none;
      outline: none;
      margin-right: 30px;
    }
    .whatsappBTN {
      font-family: "Montserrat", sans-serif;
      padding: 10px 40px;
      background: transparent;
      color: #44b34d;
      border: 1px solid #44b34d;
      border-radius: 41px;
      cursor: pointer;
      a {
        font-family: "Montserrat", sans-serif;
        color: #44b34d;
        text-decoration: none;
      }
      .whatsappIcon {
        margin-right: 10px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .heroSection {
    padding: 30px 0 50px 0;
  }
  .leftBox {
    h1 {
      font-size: 30px;
      text-align: center;
    }
    p {
      font-size: 16px;
      text-align: center;
    }
    .buttonBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      .enquireBTN {
        padding: 7px 20px;
        background: #44b34d;
        border-radius: 41px;
        color: #ffffff;
        border: none;
        outline: none;
        cursor: pointer;
        margin-bottom: 20px;
      }
      .whatsappBTN {
        padding: 10px 40px;
        background: transparent;
        color: #44b34d;
        border: 1px solid #44b34d;
        border-radius: 41px;
        cursor: pointer;
        .whatsappIcon {
          margin-right: 10px;
        }
      }
    }
  }
}
