.navbar-main {
  background-color: #0e256f;
}
.loginButton {
  font-family: "Montserrat", sans-serif;
  padding: 10px 25px;
  color: #fff;
  background: #44b34d;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  border: none;
}
.navLogo {
  width: 50px;
  height: 50px;
}
.navbar-dark .navbar-toggler {
  border-color: #fff;
}
.nav-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-right: 15px;
  cursor: pointer;
}
.nav-link:hover {
  color: #3bbcdc;
}

@media screen and (max-width: 768px) {
  .navbar-main {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .eWjCzc {
    display: none !important;
  }
  .navLogoText {
    display: none;
  }
}
