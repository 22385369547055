.our-blogs {
  text-align: justify;
  overflow-x: hidden;
  background-color: #f4f4f6;
  padding: 2% 0;
  min-height: calc(90vh);
  color: #0e256f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .blog-card {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 50%;
    color: #0e256f;
    min-height: 53vh;
    border-radius: 0%;
  }
  .blog-date {
    position: absolute;
    bottom: 2%;
    font-size: 14px;
  }
  .blog-title {
    text-decoration: double !important;
    padding: 2% 0 1% 0;
    font-family: "Montserrat", sans-serif;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-body {
    padding: 1%;
  }
  .blog-content {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 10px;
    font-weight: 200;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.blog-detail {
  max-width: 1000px;
  text-align: justify;
  line-height: 1.5;
  .leading-snug {
    font-family: "Montserrat", sans-serif;
  }
  .responsive-img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}
