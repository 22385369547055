.testimonial-carousel {
  background-color: #0e256f;
  // background-color: #18354c;
  // background-image: url(../../resources/images/bg-image.png);
  // // background-position: center;
  // background-size: 70% 40%;
  // background-repeat: no-repeat;
  // background-position: center;
  min-height: calc(80vh);
  color: #fff;
  padding-bottom: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .testimonial-cards {
    h1 {
      color: #00213a;
      font-family: "Montserrat", sans-serif;
    }
    .testimonial-desig {
      font-family: "Poppins", sans-serif;
      color: #7b7b7b;
    }
  }

  .eLPooy {
    width: 100%;
  }
  .blueHexagonInnerLine {
    position: absolute;
    width: 100px;
    top: -50px;
    left: 100px;
  }
  .blueHexagon {
    position: absolute;
    top: 0;
    right: 50px;
  }

  .testimonial-cards {
    padding: 5%;
    background-color: #fff;
    // border-radius: 50px;
    .testimonial-img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: block;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        object-fit: cover;
      }
    }
    .testimonial-content {
      display: flex;
      justify-content: center;
      text-align: left;
      flex-direction: column;
      color: #00213a;
      p {
        font-size: 16px;
        font-family: "poppins";
      }
    }
  }
}
.hJKYRZ {
  background-color: #fff;
}
.guIbWC {
  border-radius: 20px;
}
.krmNah {
  background-color: #fff !important;
  box-shadow: 0 0 3px 3px #3bbcdc !important;
}
.jhmYzC {
  background-color: rgb(155, 155, 155) !important;
}
.hJJzxj:hover:enabled {
  background-color: #3bbcdc;
}
.eWjCzc {
  background-color: #fff !important;
  &:hover {
    color: #00213a !important;
  }
}
@media screen and (max-width: 650px) {
  .llqNir {
    margin: 0px !important;
  }
  .testimonial-carousel {
    .blueHexagonInnerLine {
      position: absolute;
      width: 100px;
      top: -50px;
      left: 30px;
    }
    .blueHexagon {
      position: absolute;
      top: 0;
      right: 50px;
    }
  }
}
@media (min-width: 300px) and (max-width: 768px) {
  .krmNah {
    height: 7px !important;
    width: 7px !important;
  }
  .jhmYzC {
    height: 7px !important;
    width: 7px !important;
  }
}
