.app {
  /* background-color: #00213a; */
}
.whatsAppBTN {
  width: 70px;
  height: 70px;
  background: transparent;
  color: #3bdc48;
  border: 2px solid #3bdc48;
  border-radius: 41px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  z-index: 9999;
  right: 20px;
  transition: all 0.2s ease-in-out;
}
.whatsAppBTN > a {
  text-decoration: none;
  color: #3bdc48;
  transition: all 0.2s ease-in-out;
}
.whatsAppBTN:hover > a {
  color: #fff;
}
.whatsAppIcon {
  font-size: 30px;
}
.whatsAppBTN:hover {
  border: 2px solid #fff;
  color: #fff;
}
.whatsappIconLink {
  text-decoration: none;
  z-index: 10;
  position: fixed;
  bottom: 10px;
  right: 10px;
transition: all .5s ease-in-out;
}
.whatsappIconLink >img{
  width: 60px;
  height: 60px;
}
.whatsappIconLink:hover{
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 650px) {
  .sc-bczRLJ {
    display: none !important;
  }
}
